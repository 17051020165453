import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useLocation } from '@reach/router'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'

import useClickOutsideHandler from '../utils/useClickOutsideHandler'
import LanguageSwitch from './LanguageSwitch'
import { Namespace } from '../constants/i18n'

interface NavItemProps {
  to: string
  children: string
}

const NavItem = ({ to, children }: NavItemProps): JSX.Element => {
  const location = useLocation()
  const isActive = location.pathname.indexOf(to) > -1

  return (
    <Link
      to={to}
      className={`flex flex-col justify-center px-6 py-4 md:py-auto h-full relative ${
        isActive
          ? 'font-bold md:font-normal'
          : 'md:hover:text-teal-400 hover:bg-gray-200 md:hover:bg-white'
      }`}
    >
      {children}
      <div
        className={`absolute bottom-0 inset-x-0 bg-teal-400 ${
          isActive ? 'h-1' : 'h-0'
        }`}
      ></div>
    </Link>
  )
}

const Header = (): JSX.Element => {
  const { iggywashLogo, menuIcon } =
    useStaticQuery(
      graphql`
        query {
          iggywashLogo: file(relativePath: { eq: "iggywash-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          menuIcon: file(relativePath: { eq: "hamburger-menu.png" }) {
            childImageSharp {
              fluid(maxWidth: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `,
    ) || {}

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navMenuRef = useRef(null)
  const { t } = useTranslation(Namespace.HeaderFooter)

  useClickOutsideHandler(
    navMenuRef,
    () => {
      if (isMenuOpen) {
        setIsMenuOpen(false)
      }
    },
    [isMenuOpen],
  )

  return (
    <header className="shadow text-gray-700 relative bg-white">
      <div className="max-w-screen-xl h-16 mx-auto flex px-6 md:px-20">
        <Link className="block w-20 my-auto" to="/">
          {iggywashLogo && <Img fluid={iggywashLogo.childImageSharp.fluid} />}
        </Link>
        <nav
          ref={navMenuRef}
          className={`flex flex-col md:flex-row md:space-x-8 absolute top-16 inset-x-0 md:static md:ml-auto bg-gray-100 md:bg-white shadow md:shadow-none overflow-hidden md:h-full transition-opacity duration-500 ${
            isMenuOpen
              ? 'border-t border-gray-300 md:border-0 opacity-100'
              : 'h-0 md:h-auto opacity-0 md:opacity-100'
          }`}
        >
          <NavItem to="/about">{t('navigation.about')}</NavItem>
          <NavItem to="/faq">{t('navigation.faq')}</NavItem>
          <LanguageSwitch />
        </nav>
        <button
          className="ml-auto my-auto space-x-2 md:hidden flex flex-row rounded hover:bg-gray-200 py-1 px-2"
          onClick={() => setIsMenuOpen((isOpen) => !isOpen)}
        >
          <p className="text-xs my-auto">Menu</p>
          {menuIcon && (
            <Img className="w-6" fluid={menuIcon.childImageSharp.fluid} />
          )}
        </button>
      </div>
    </header>
  )
}

export default Header
