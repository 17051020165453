import React from 'react'

const BetaBanner = (): JSX.Element => (
  <div className="bg-black text-white py-3" role="alert">
    <div className="flex max-w-screen-xl mx-auto px-6 sm:px-12">
      <div className="py-1">
        <svg
          className="fill-current h4 w-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
        </svg>
      </div>
      <div className="flex flex-col justify-center">
        <div className="text-sm font-serif flex flex-row space-x-2">
          <p className="font-bold">BETA :</p>
          <p className="flex-1">
            System is being tested and improved. This is not the final version.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default BetaBanner
