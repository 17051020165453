import * as React from 'react'
import Layout, { LayoutProps } from './Layout'
import Header from '../Header'
import Footer from '../Footer'
import BetaBanner from '../BetaBanner'
import GradientHeader from '../GradientHeading'

type LayoutWithHeaderAndFooterProps = {
  heading?: string
} & LayoutProps

const LayoutWithHeaderAndFooter = ({
  children,
  heading,
}: LayoutWithHeaderAndFooterProps): JSX.Element => (
  <>
    <BetaBanner />
    <Header />
    {heading && <GradientHeader heading={heading} />}
    <Layout className="mt-8 mb-12 md:my-16">{children}</Layout>
    <Footer />
  </>
)

export default LayoutWithHeaderAndFooter
