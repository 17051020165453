import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { DisplayXLarge } from './typography'

interface GradientHeaderProps {
  heading: string
}

interface ContainerWithBackgroundProps {
  imageUrl: string
}

const ContainerWithBackground = styled.div`
  background-image: url("${(props: ContainerWithBackgroundProps): string =>
    props.imageUrl}");
  background-color: #2FBEB9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const GradientHeader = ({ heading }: GradientHeaderProps): JSX.Element => {
  const { bgImage } =
    useStaticQuery(
      graphql`
        query {
          bgImage: file(relativePath: { eq: "gradient-heading-bg.png" }) {
            childImageSharp {
              fixed(width: 1440) {
                src
              }
            }
          }
        }
      `,
    ) || {}
  return (
    <ContainerWithBackground
      className="h-32 md:h-64 px-6 flex flex-col justify-center"
      imageUrl={bgImage ? bgImage.childImageSharp.fixed.src : null}
    >
      <DisplayXLarge as="h1" className="text-white text-center">
        {heading}
      </DisplayXLarge>
    </ContainerWithBackground>
  )
}

export default GradientHeader
