import { createTypography } from './helpers'

export const DisplayXLarge = createTypography(
  'text-m-display-x-large lg:text-d-display-x-large font-sans',
)
export const DisplayLarge = createTypography(
  'text-m-display-large lg:text-d-display-large font-sans',
)
export const DisplayMedium = createTypography(
  'text-m-display-medium lg:text-d-display-medium font-sans',
)
export const DisplayXSmall = createTypography(
  'text-m-display-x-small lg:text-d-display-x-small font-sans',
)

export const HeadingLarge = createTypography('text-d-heading-large font-sans')
export const HeadingMedium = createTypography('text-d-heading-medium font-sans')

export const ParagraphLarge = createTypography(
  'text-m-paragraph-large lg:text-d-paragraph-large',
)
export const ParagraphMedium = createTypography('text-d-paragraph-medium')

export const LabelMedium = createTypography('text-d-label-medium font-bold')
export const LabelSmall = createTypography('text-d-label-small text-gray-600')
