import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { LanguageName } from '../constants/i18n'

const LocaleIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2.06 11C2.24338 12.4558 2.82361 13.8331 3.73721 14.9813C4.65082 16.1295 5.8626 17.0043 7.24 17.51C6.44503 15.4311 6.03174 13.2257 6.02 11H2.06ZM2.06 9H6.02C6.03174 6.77428 6.44503 4.56894 7.24 2.49C5.8626 2.99572 4.65082 3.87052 3.73721 5.0187C2.82361 6.16687 2.24338 7.5442 2.06 9ZM17.94 9C17.7566 7.5442 17.1764 6.16687 16.2628 5.0187C15.3492 3.87052 14.1374 2.99572 12.76 2.49C13.555 4.56894 13.9683 6.77428 13.98 9H17.94ZM17.94 11H13.98C13.9683 13.2257 13.555 15.4311 12.76 17.51C14.1374 17.0043 15.3492 16.1295 16.2628 14.9813C17.1764 13.8331 17.7566 12.4558 17.94 11ZM8.02 11C8.18 14.95 9.25 18 10 18C10.75 18 11.82 14.95 11.98 11H8.02ZM8.02 9H11.98C11.82 5.05 10.75 2 10 2C9.25 2 8.18 5.05 8.02 9ZM10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20Z" />
  </svg>
)

const LanguageSwitch = (): JSX.Element => {
  const { changeLanguage, languages, language } = useI18next()
  const languageToChange = languages.find((lang) => lang !== language)
  return (
    <button
      className="flex flex-row px-6 py-4 md:py-auto h-full md:hover:text-teal-400 hover:bg-gray-200 md:hover:bg-white"
      onClick={() => changeLanguage(languageToChange)}
    >
      <div className="my-auto mr-2">
        <LocaleIcon className="h-4 fill-current" />
      </div>
      <div className="my-auto">
        {languageToChange && LanguageName[languageToChange]}
      </div>
    </button>
  )
}

export default LanguageSwitch
