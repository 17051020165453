import React from 'react'
import { useStaticQuery, graphql, GatsbyLinkProps } from 'gatsby'
import Img from 'gatsby-image'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../constants/i18n'

const InternalLink = ({ children, ...props }: GatsbyLinkProps<any>) => (
  <Link
    className="hover:underline px-4 py-1 md:px-0 md:ml-0 md:mr-12 my-auto"
    {...props}
  >
    {children}
  </Link>
)

const ExternalLink = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => (
  <a
    className="flex flex-col justify-center h-auto w-24 lg:w-40"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </a>
)

const Footer = (): JSX.Element => {
  const quriedImages =
    useStaticQuery(
      graphql`
        query {
          facebookIcon: file(relativePath: { eq: "social-facebook.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          lineIcon: file(relativePath: { eq: "social-line.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          twitterIcon: file(relativePath: { eq: "social-twitter.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          moruLogo: file(relativePath: { eq: "MORU_FS_BLUE_RGB.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          oxfordLogo: file(relativePath: { eq: "ox_brand2_pos_rect.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          thoughtworksLogo: file(
            relativePath: { eq: "ThoughtWorks_logo_white.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `,
    ) || {}

  const { t } = useTranslation(Namespace.HeaderFooter)

  return (
    <footer className="pt-12 pb-4 text-white text-sm bg-dark-blue">
      <div className="max-w-screen-xl mx-auto px-6 sm:px-12">
        <div className="flex flex-col md:flex-row-reverse">
          <div className="flex-1 text-center md:text-right mb-12 md:mb-0">
            <p className="mb-2">{t('contactViaEmail')}</p>
            <a
              className="font-bold"
              href="mailto:keepcalmandwash@gmail.com"
              title="Mail to us"
            >
              keepcalmandwash@gmail.com
            </a>
          </div>
          {Object.keys(quriedImages).length > 0 && (
            <div className="flex-1 flex flex-row space-x-16 md:pr-20 md:self-start justify-center md:justify-start">
              <ExternalLink
                href="https://www.tropmedres.ac/"
                title="MORU Tropical Health Network"
              >
                <Img fluid={quriedImages.moruLogo.childImageSharp.fluid} />
              </ExternalLink>
              <ExternalLink
                href="https://www.thoughtworks.com/"
                title="ThoughtWorks"
              >
                <Img
                  fluid={quriedImages.thoughtworksLogo.childImageSharp.fluid}
                />
              </ExternalLink>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row mt-12 mb-4">
          <div className="flex flex-wrap justify-center md:justify-start flex-1 text-gray-500">
            <InternalLink to="/about">{t('navigation.about')}</InternalLink>
            <InternalLink to="/faq">{t('navigation.faq')}</InternalLink>
            <InternalLink to="/terms-conditions">
              {t('navigation.termsAndConditions')}
            </InternalLink>
            <InternalLink to="/feedback">
              {t('navigation.feedback')}
            </InternalLink>
          </div>
          <div className="flex justify-center md:justify-end text-xs text-gray-500">
            <p className="text-right">Copyright &copy; by iggyhandwash.com</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
