import { useEffect } from 'react'

const useClickOutsideHandler = (
  ref: React.RefObject<Element>,
  callback: (e: InputEvent, ...params: any[]) => void,
  deps?: any[],
): void => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, ...deps])
}

export default useClickOutsideHandler
