import React from 'react'

interface TypoProps {
  children: unknown
  as?: string
  className?: string
}

export const createTypography = (baseClass: string) => ({
  children,
  as = 'p',
  className = '',
  ...props
}: TypoProps): JSX.Element => {
  const Astag = as
  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Astag className={`${baseClass} ${className}`} {...props}>
      {children}
    </Astag>
  )
}
